console.log("It's working!");
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
const axios = require("axios").default;
/* ANCHORING
================================================ */
$(".database-submit").click(function () {
  if ($(".blank").val().length === 0) {
    axios
      .post("https://sheetdb.io/api/v1/qihdvk31oe2aw", {
        Date: $('input[name="Date"]').val(),
        Institution: $('input[name="Institution"]').val(),
        Strasse: $('input[name="Strasse"]').val(),
        Postleitzahl: $('input[name="Postleitzahl"]').val(),
        Ort: $('input[name="Ort"]').val(),
        Kontaktperson: $('input[name="Kontaktperson"]').val(),
        Email: $('input[name="Email"]').val(),
        Mobile: $('input[name="Mobile"]').val(),
        // Weiterbildung
        Funktion: $('input[name="Funktion"]').val(),
        Zielgruppe: $('input[name="Zielgruppe"]').val(),
        Wunschtermin: $('input[name="Wunschtermin"]').val(),
        Erreichbar: $('input[name="Erreichbar"]').val(),
        InputauswahlWeiterbildung: $(
          'select[name="InputauswahlWeiterbildung"]'
        ).val(),
        // Tag / Tagung
        FunktionTagung: $('input[name="FunktionTagung"]').val(),
        InputauswahlTagTagung: $('select[name="InputauswahlTagTagung"]').val(),
        // Kiosk
        Product1name: $(".Product1name").html(),
        Product1Q: $('input[name="Product1Q"]').val(),
        Product2name: $(".Product2name").html(),
        Product2Q: $('input[name="Product2Q"]').val(),
        Product3name: $(".Product3name").html(),
        Product3Q: $('input[name="Product3Q"]').val(),
        Product4name: $(".Product4name").html(),
        Product4Q: $('input[name="Product4Q"]').val(),
        Product5name: $(".Product5name").html(),
        Product5Q: $('input[name="Product5Q"]').val(),
        Product6name: $(".Product6name").html(),
        Product6Q: $('input[name="Product6Q"]').val(),
        Product7name: $(".Product7name").html(),
        Product7Q: $('input[name="Product7Q"]').val(),
        Product8name: $(".Product8name").html(),
        Product8Q: $('input[name="Product8Q"]').val(),
        Product9name: $(".Product9name").html(),
        Product9Q: $('input[name="Product9Q"]').val(),
        Product10name: $(".Product10name").html(),
        Product10Q: $('input[name="Product10Q"]').val(),
        Product11name: $(".Product11name").html(),
        Product11Q: $('input[name="Product11Q"]').val(),
        Product12name: $(".Product12name").html(),
        Product12Q: $('input[name="Product12Q"]').val(),
        Product13name: $(".Product13name").html(),
        Product13Q: $('input[name="Product13Q"]').val(),
        Product14name: $(".Product14name").html(),
        Product14Q: $('input[name="Product14Q"]').val(),
        Product15name: $(".Product15name").html(),
        Product15Q: $('input[name="Product15Q"]').val(),
      })
      .then(function (response) {
        console.log("success", response);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  } // honeypot
});

if ($(window).width() < 767) {
  $(document).on("click", 'a[href^="#"]', function (e) {
    e.preventDefault();
    var target = this.hash;
    var $target = $(target);
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $target.offset().top - 0,
        },
        1000
      );
  });
  $(window).scroll(function () {
    if ($(document).scrollTop() > 100) {
      jQuery(".logo-long").removeClass("active");
      jQuery(".logo-short").addClass("active");
    } else {
      jQuery(".logo-short").removeClass("active");
      jQuery(".logo-long").addClass("active");
    }
  });
}
// TABs
$(".tab-panes .tab-pane").hide();
$(".tab-panes .tab-pane:first").show();
$(".tabs-nav a").click(function () {
  $(".tabs-nav a").removeClass("active");
  $(this).addClass("active");
  $(".tab-panes .tab-pane").hide();
  var indexer = $(this).index(); //gets the current index of (this) which is #nav li
  $(".tab-panes .tab-pane:eq(" + indexer + ")").fadeIn(); //uses whatever index the link has to open the corresponding box
});

// open correct tab
$("#kiosk .content--cta .button").click(function (e) {
  e.preventDefault();
  $(".modal").addClass("is-active");
  $(".modal").addClass("bg-geraldine");
  $(".tabs-nav a").removeClass("active");
  $(".tab-panes .tab-pane").hide();
  $(".tabs-nav a:nth-of-type(3)").addClass("active");
  $(".kiosk-pane").show();
});

$("#database").submit(function (e) {
  var formData = $(this).serialize();
  console.log("formdata", formData);
  if ($(".blank").val().length === 0) {
    // honeypot
    axios({
      method: "post",
      url: "sendmail.php",
      data: formData,
    })
      .then(function (response) {
        //handle success
        console.log("formdata", response);
      })
      .catch(function (response) {
        //handle error
        console.log("error", response);
      });
  } else {
    console.log("spam");
  }

  e.preventDefault();
  $(this).hide();
  $(".form-title").addClass("hidden");
  $(".thankyou").addClass("visible");
});
$(".contact-modal").click(function (e) {
  e.preventDefault();
  var color = $(this).data("color");
  $(".modal-popup").addClass(color);
  $(".modal-popup").addClass("is-active");
});
$(".button-modal").click(function (e) {
  e.preventDefault();
  var color = $(this).parents("section").data("color");
  console.log("THECOLOR", color);
  $(".modal-popup").addClass(color);
  $(".modal-popup").addClass("is-active");
});
// close modal
$(".close-modal, .modal-background").click(function () {
  $(".modal").removeClass(
    "is-active bg-black bg-white bg-geraldine bg-black-white bg-black-white-font"
  );
  $(".form-title").removeClass("hidden");
  $(".thankyou").removeClass("visible");
  $("#database").show();
  // revert to first tab
  $(".tabs-nav a").removeClass("active");
  $(".tab-panes .tab-pane").hide();
  $(".tabs-nav a:nth-of-type(1)").addClass("active");
  $(".weiterbildung-pane").show();
  // remove required from all fields on modal close to reset state
  var elements = document.getElementsByClassName("required-input");
  for (var i = 0; i < elements.length; i++) {
    /* remove the "required" attribute from the element */
    elements[i].removeAttribute("required", "");
  }
  $(".required-input").removeClass("required-input");
});
// if should open tagung
$(".contact-modal[data-tab='tagung'], .button-modal[data-tab='tagung']").click(
  function (e) {
    $(".tabs-nav a").removeClass("active");
    $(".tab-panes .tab-pane").hide();
    $(".tabs-nav a:nth-of-type(2)").addClass("active");
    $(".tagung-pane").show();
    // tagung fields to required
    $(".tagung-input").addClass("required-input");
    var elements = document.getElementsByClassName("tagung-input");
    for (var i = 0; i < elements.length; i++) {
      /* remove the "required" attribute from the element */
      elements[i].setAttribute("required", "");
    }
  }
);
// if should open weiterbildung
$(
  ".contact-modal[data-tab='weiterbildung'], .button-modal[data-tab='weiterbildung']"
).click(function (e) {
  // weiterbildung fields to required
  $(".weiterbildung-input").addClass("required-input");
  var elements = document.getElementsByClassName("weiterbildung-input");
  for (var i = 0; i < elements.length; i++) {
    /* remove the "required" attribute from the element */
    elements[i].setAttribute("required", "");
  }
});
// if should open kiosk
$(".contact-modal[data-tab='kiosk'], .button-modal[data-tab='kiosk']").click(
  function (e) {
    $(".tabs-nav a").removeClass("active");
    $(".tab-panes .tab-pane").hide();
    $(".tabs-nav a:nth-of-type(3)").addClass("active");
    $(".kiosk-pane").show();
  }
);
// mobnile
